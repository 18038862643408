import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import userStore from "@horo/core/modules/user/store";
import categoryStore from "@horo/core/modules/category/store";
import envConfig from "../config/envConfig";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        paymentName: " ",
        backendUrl: "",
        backendMpiteSanUrl: envConfig.appBackendMpiteSanUrl,
        backendOnepayUrl: envConfig.appBackendOnePayUrl,
        paymentPortalUrl: envConfig.paymentPortalURL,
        merchant_id: envConfig.bpPayMerchantId,
        service_name: envConfig.bpPayServiceName,
        email: envConfig.bpPayEmail,
        password: envConfig.bpPayPassword,
        amount: 0,
        order_id: "",
        onepayPhoneNumber: "",
    },
    mutations: {
        getPaymentName(state, paymentName) {
            state.paymentName = paymentName;
        },
    },
    actions: {},
    getters: {
        getRoutePath() {
            return router.currentRoute.path;
        },
        getRouteParams() {
            return router.currentRoute.params;
        },
    },
    modules: {
        userStore,
        categoryStore,
    },
});
