<template>
  <div></div>
</template>

<script>
export default {
  name: `withPaymenthome`,
  data() {
    return {
      payment: "",
    };
  },
  created() {
    this.getPaymentName();
    if (this.$store.state.paymentName == "mpitesan") {
      this.$store.state.backendUrl = this.$store.state.backendMpiteSanUrl;
    } else if (this.$store.state.paymentName == "onepay") {
      this.$store.state.backendUrl = this.$store.state.backendOnepayUrl;
      this.$store.state.onepayPhoneNumber = this.$route.query.UserID;
    }

    this.$router.push({
      name: "home",
    });
  },

  methods: {
    getPaymentName() {
      this.payment = this.$route.params.payment;
      this.$store.commit("getPaymentName", this.payment);
    },
  },
};
</script>
