import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import WithPaymentHome from "../views/WithPaymentHome.vue";
import RedirectHome from "../views/RedirectHome.vue";
import paymentPortal from "../views/paymentPortal";
import PaymentSuccess from "../views/PaymentSuccess";
import PaymentFail from "../views/PaymentFail";
import PaymmentWithContentCategory from '../views/PaymentWithContentCategory.vue';

Vue.use(VueRouter);

const Normal = () =>
    import(/* webpackChunkName: "about" */ "../views/Normal.vue");
const Content = () =>
    import(/* webpackChunkName: "about" */ "../views/Content.vue");
const Special = () =>
    import(/* webpackChunkName: "about" */ "../views/Special.vue");
const Direct = () =>
    import(/* webpackChunkName: "about" */ "../views/Direct.vue");
const Phone = () =>
    import(/* webpackChunkName: "about" */ "../views/Phone.vue");
const Order = () =>
    import(/* webpackChunkName: "about" */ "../views/Order.vue");
const Category = () =>
    import(/* webpackChunkName: "about" */ "../views/Category.vue");
const Free = () => import(/* webpackChunkName: "about" */ "../views/Free.vue");
const Orders = () =>
    import(/* webpackChunkName: "about" */ "../views/Orders.vue");
const Star = () => import(/* webpackChunkName: "about" */ "../views/Star.vue");
const Zoom = () => import(/* webpackChunkName: "about" */ "../views/Zoom.vue");
const BaydinSayar = () =>
    import(/* webpackChunkName: "about" */ "../views/BaydinSayar.vue");

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/payment/:payment",
        name: "withPaymenthome",
        component: WithPaymentHome,
    },
    // Redirect Payment Portal
    {
        path: "/paymentPortal",
        name: "paymentPortal",
        component: paymentPortal,
    },
    // End
    // Redirect component for un exist route
    {
        path: "/PaymentSuccess/:payment",
        name: "PaymentSuccess",
        component: PaymentSuccess,
    },
    {
        path: "/PaymentFail/:payment",
        name: "PaymentFail",
        component: PaymentFail,
    },
    // end
    {
        path: "/payment",
        name: "RedirectHome",
        component: RedirectHome,
    },
    {
        path: "/normal/:categoryId",
        name: "normal",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Normal,
    },
    {
        path: "/special/:categoryId",
        name: "special",
        component: Special,
    },
    {
        path: "/direct/:categoryName?",
        name: "direct",
        component: Direct,
    },
    {
        path: "/phone",
        name: "phone",
        component: Phone,
    },
    {
        path: "/content/:categoryId",
        name: "content",
        component: Content,
    },
    {
        path: "/order",
        name: "order",
        component: Order,
    },
    {
        path: "/category/:categoryName",
        name: "category",
        component: Category,
    },
    {
        path: "/free",
        name: "free",
        component: Free,
    },
    {
        path: "/orders",
        name: "orders",
        component: Orders,
    },
    {
        path: "/star/:categoryName",
        name: "star",
        component: Star,
    },
    {
        path: "/zoom/:baydinsayar",
        name: "zoom",
        component: Zoom,
    },
    {
        path: "/baydinsayar",
        name: "baydinsayar",
        component: BaydinSayar,
    },
    {
        path: "/paymentMethodWithContentCategory/:paymentMethod/:contentCategory",
        name: "paymentMethodWithContentCategory",
        component: PaymmentWithContentCategory,
    },
    
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
