let appEnv = process.env.VUE_APP_ENV;

let appBackendMpiteSanUrl = "";
let appBackendOnePayUrl = "";
let horoUrl = "";

if (appEnv === "local") {
    appBackendMpiteSanUrl = process.env.VUE_APP_LOCAL_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_LOCAL_BACKEND_ONEPAY_URL;
    horoUrl = process.env.VUE_APP_LOCAL_HORO_URL;
} else if (appEnv === "staging") {
    appBackendMpiteSanUrl = process.env.VUE_APP_STAGING_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_STAGING_BACKEND_ONEPAY_URL;
    horoUrl = process.env.VUE_APP_STAGING_HORO_URL;
} else if (appEnv === "production") {
    appBackendMpiteSanUrl = process.env.VUE_APP_PRODUCTION_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_PRODUCTION_BACKEND_ONEPAY_URL;
    horoUrl = process.env.VUE_APP_PRODUCTION_HORO_URL;
} else {
    appBackendMpiteSanUrl = process.env.VUE_APP_LOCAL_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_LOCAL_BACKEND_ONEPAY_URL;
    horoUrl = process.env.VUE_APP_LOCAL_HORO_URL;
}

let paymentPortalURL = process.env.VUE_APP_PAYMENT_PORTAL_URL;
let bpPayMerchantId = process.env.VUE_APP_BP_PAY_MERCHANT_ID;
let bpPayServiceName = process.env.VUE_APP_BP_PAY_SERVICE_NAME;
let bpPayEmail = process.env.VUE_APP_BP_PAY_EMAIL;
let bpPayPassword = process.env.VUE_APP_BP_PAY_PASSWORD;

export default {
    appBackendMpiteSanUrl,
    appBackendOnePayUrl,
    horoUrl,
    paymentPortalURL,
    bpPayMerchantId,
    bpPayServiceName,
    bpPayEmail,
    bpPayPassword,
};
